<template>
  <div>
    <b-card>
      <b-card-header>
        <b-card-title class="d-flex justify-content-center">{{ $t("Approaching Events")
          }}</b-card-title>
      </b-card-header>
      <b-card-body>
        <app-timeline>
          <b-collapse visible v-for="(item, index) in calendarStats" :key="'entry' + index">
            <app-timeline-item :icon="'ClockIcon'" :variant="statusVariant(timeLeft(item.start))"
              class="mt-1 border-bottom-secondary border-left-secondary" v-if="index < showUpcomingRowCount">
              <!-- <div -->
              <div class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1 w-100">
                <h4 style="width: 50% !important">
                  {{ item.title }}
                </h4>
                <p style="width: 30% !important">{{ item.extendedProps.calendar }}</p>
                <small class="timeline-item-time text-nowrap text-muted ml-1"
                  style="text-align: right; width: 20% !important; color: #fff !important">{{ timeLeft(item.start) }}</small>
              </div>
              <!-- </div> -->
              <!-- <div
            class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1"
          > -->
              <!-- <b-button
              variant="primary-inverse"
              v-if="new Date(item.start).getTime() <= Date.now()"
              @click=""
            >
              <feather-icon size="16" variant="primary" icon="CheckIcon"></feather-icon
            ></b-button> -->
              <!-- </div> -->
              <!-- <span v-if="item.extendedProps.guests.length > 0">Danışanlar :</span> -->
              <!-- https://bootstrap-vue.org/docs/components/collapse danisanlari collapible yapsak daha iyi olacak sanki -->
              <b-collapse :id="item._id" :ref="'collapse' + item._id" v-if="item.extendedProps.guests.length > 0"
                :visible="false">
                <ul class="subject-list">
                  <li class="subject-row border-left-secondary border-bottom-secondary"
                    v-for="(guest, index) in item.extendedProps.guests">
                    <b-button variant="outline" size="sm" :to="`/clientlist/${guest.id._id}`">
                      <feather-icon size="16" variant="info" icon="UserIcon" />
                      <span class="ml-1">{{ guest.name }}</span>
                    </b-button>
                    <!-- Yazdir/Sonuc gir butonlari direk takvime tasinabilir. Aciklama da cok uzun olunca cirkin duruyor. Bence buton koyup takvim duzenleme ekranini cikaralim.-->
                    <!-- takvim tarih girisini de bi elden gecirelim saat falan cok belirsiz milletin kafasi karisacak -->
                    <b-button variant="danger" size="sm" v-if="!guest.id.program.active">
                      <a class="timeline-action">{{ $t("Program Inactive") }}</a>
                    </b-button>
                    <b-button variant="primary" size="sm" v-else-if="
                          guest.id.program.exercisesDone ==
                          guest.id.program.exercisesPrinted
                        " @click="printExerciseFun(guest.id._id)">
                      {{ $t("Print") }}
                    </b-button>
                    <b-button v-else size="sm" :to="{ name: 'exercises-detail', params: { id: guest.id._id } }">
                      <a class="timeline-action">{{ $t("Missing Result") }}</a></b-button>
                  </li>
                </ul>
              </b-collapse>
              <div class="d-flex flex-row justify-content-between align-items-baseline">
                <span class="timeline-note-area">
                  {{ item.extendedProps.description }}
                </span>
                <b-button v-b-toggle="item._id" variant="outline-primary" v-if="item.extendedProps.guests.length > 0">
                  <feather-icon size="16" variant="primary" icon="UserIcon" />
                </b-button>
              </div>
            </app-timeline-item>
          </b-collapse>
        </app-timeline>
      </b-card-body>
    </b-card>
    <PrintExerciseMain :user-i-d="printID" :show-print="showPrint" />
  </div>
</template>

<script>
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItemSecond.vue";
import axios from "@axios";
import {
  BCardHeader,
  BCardTitle,
  BBadge,
  BButton,
  BCollapse,
  VBToggle,
  BCardBody,
  BCard,
} from "bootstrap-vue";
import PrintExerciseMain from "@/views/apps/user/exercise-print/PrintExerciseMain.vue";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
// import store from "@/store";
const {
  axiosRoutes: { calendar: path },
} = require("/G_CONFIG");

export default {
  props: ["refresh"],
  watch: {
    refresh(newVal, oldVal) {
      console.log("refreshFired");
      this.populateList();
    },
  },

  data() {
    return {
      calendarStats: [],
      showPrint: false,
      printID: "",
      nowDate: Date.now(),
      showUpcomingRowCount: 5,
    };
  },
  components: {
    AppTimeline,
    AppTimelineItem,
    BCardHeader,
    BCardTitle,
    BBadge,
    BButton,
    BCollapse,
    PrintExerciseMain,
    FeatherIcon,
    BCardBody,
    BCard,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  created() {
    this.populateList();
  },
  methods: {
    printExerciseFun(id) {
      this.printID = id;
      // console.log(this.printID);
      this.showPrint = true;
    },
    populateList() {
      axios.get(path.getAll, { params: { filter: "new" } }).then((res) => {
        this.calendarStats = res.data; /*.filter((item) => {});*/
      });
    },
    timeLeft(date) {
      date = parseInt(date);
      let day, hour, minute, second;
      const timeLeft = date - Date.now();
      const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
      const hours = Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));

      if (days < -1) {
        day = days + 1 + " " + this.$t("Day");
      } else if (days > 0) {
        day = days + " " + this.$t("Day");
      } else {
        day = "";
      }
      if (hours < -1) {
        hour = hours + 1 + " " + this.$t("Hour");
      } else if (hours > 0) {
        hour = hours + " " + this.$t("Hour");
      } else {
        hour = "";
      }
      if (minutes < -1) {
        minute = minutes + 1 + " " + this.$t("Minute");
      } else if (minutes >= 0) {
        minute = minutes + " " + this.$t("Minute");
      } else {
        minute = "";
      }

      return day + " " + hour + " " + minute;
    },
  },
  computed: {
    statusVariant() {
      return (timeLeft) => {
        if (timeLeft.includes(this.$t("Day"))) {
          return "success";
        } else if (timeLeft.includes(this.$t("Hour"))) {
          return "warning";
        } else if (timeLeft.includes(this.$t("Minute"))) {
          return "danger";
        }
      };
      /* const statusColor = {

        dikkat: "light-primary",
        hafiza: "light-danger",
        konsantrasyon: "warning",
        mantik: "light-success",
        Applied: "light-info",

      };

      return (status) => statusColor[status]; */
    },
  },
};
</script>
<style scoped>
/* .timeline-action {
  margin-left: 10px;
} */
.subject-row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding-left: 10px;
  margin-bottom: 5px;
  padding-bottom: 5px;
}

.subject-list {
  list-style-type: none;
  padding: 0;
}

.timeline-item::after {
  display: none;
}

.timeline-note-area {
  word-wrap: break-word;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 90%;
  min-height: 20px;
  max-height: 20px;
  transition: all 0.5s ease;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.timeline-note-area::-webkit-scrollbar {
  display: none;
}

.timeline-note-area:hover {
  transition: all 0.5s ease;
  overflow: visible;
  min-height: 20px;
  height: auto;
  max-height: 200px;
  animation: 1s delay-overflow;
}

@keyframes delay-overflow {
  from {
    overflow: auto;
  }
}

/* .rounded {
  border-radius: 20px !important;
} */
/* .analytics-row {
  border-bottom: 1px solid;
  border-left: 1px solid;
} */
</style>
