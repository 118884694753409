import { $themeColors } from "@themeConfig";

export default {
  chart: {
    type: "bar",
    height: 500,
    stacked: true,
    toolbar: {
      show: true,
      offsetX: 0,
      offsetY: 0,
      tools: {
        selection: true,
        zoom: true,
        zoomin: true,
        zoomout: true,
        pan: true,
        reset: true | '<img src="/static/icons/reset.png" width="20">',
        customIcons: [],
        download: false,
      },
      export: {
        csv: {
          filename: undefined,
          columnDelimiter: ",",
          headerCategory: "category",
          headerValue: "value",
          dateFormatter(timestamp) {
            return new Date(timestamp).toDateString();
          },
        },
        svg: {
          filename: undefined,
        },
        png: {
          filename: undefined,
        },
      },
      autoSelected: "zoom",
    },
  },
  // responsive: [
  //   {
  //     breakpoint: 480,
  //     options: {
  //       legend: {
  //         position: "bottom",
  //         offsetX: -10,
  //         offsetY: 0,
  //       },
  //     },
  //   },
  // ],
  xaxis: {
    labels: {
      show: true,
      style: {
        colors: $themeColors.secondary,
      },
    },
    categories: [],
  },
  yaxis: {
    labels: {
      show: true,
      style: {
        colors: $themeColors.secondary,
      },
    },
    min: 0,
    max: 100,
  },
  legend: {
    position: "bottom",
    labels: {
      colors: [
        $themeColors.secondary,
        $themeColors.secondary,
        $themeColors.secondary,
      ],
    },
  },
  plotOptions: {
    bar: {
      horizontal: false,
    },
  },
  fill: {
    opacity: 1,
  },
};
