<template>
  <div class="container">
    <b-badge variant="light-primary" id="total-badge" class="m-1"> {{ score }}% </b-badge>
    <b-tooltip target="total-badge" triggers="hover" variant="primary">
      <span>{{ $t("Current Success State") }}</span>
    </b-tooltip>
    <div class="success success-striped">
      <div class="success-bar text-center" id="score-badge" :style="{ width: `${change > 0 ? score - change : score}%` }">
        <b-badge variant="light-danger">
          <span style="color: white; box-shadow: 5px black">{{ change > 0 ? score - change : score }}%</span>
        </b-badge>
        <b-tooltip variant="primary" target="score-badge" triggers="hover">
          <span v-if="change < 0">{{ $t("Post Decrease Success") }}</span>
          <span v-else>{{ $t("Success Before Increase") }}</span>
        </b-tooltip>
      </div>
      <div :class="`bg-${change < 0 ? 'danger' : 'success'}`" :style="{ width: `${Math.abs(change)}%` }"
        id="change-badge">
        <b-badge :variant="`light-${change < 0 ? 'info' : 'primary'}`">
          <span style="color: white; box-shadow: 5px black">{{ change }}%</span>
        </b-badge>
        <b-tooltip variant="primary" target="change-badge" triggers="hover">
          <span v-if="change < 0">{{ $t("Decrease In Success") }}</span>
          <span v-else>{{ $t("Increase In Success") }}</span>
        </b-tooltip>
      </div>
    </div>
  </div>
</template>
<script>
import { BBadge, BRow, BTooltip } from "bootstrap-vue";
export default {
  components: {
    BBadge,
    BRow,
    BTooltip,
  },
  data() {
    return {
      /* pauseBar: false, */
    };
  },
  props: {
    pausedState: {
      type: Boolean,
      default: false,
    },
    transition: {
      type: Boolean,
      default: true,
    },
    score: { type: Number, default: 0 },
    change: { type: Number, default: 0 },
  },
  computed: {
    pauseBarAnimation() {
      if (this.transition) return true;
      return this.pausedState;
    },
  },
};
</script>
<style scoped lang="scss">
.pauseBar {
  animation-play-state: paused !important;
}

$background: #2c303a;

.container {
  /* width: 500px; */
  text-align: center;
}

.success {
  display: flex;
  padding: 6px;
  background: rgba(0, 0, 0, 0.25);
  // border-radius: 6px;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.25), 0 1px rgba(255, 255, 255, 0.08);
}

.success-bar {
  background-color: rgb(0, 207, 232);
  // border-radius: 4px;
  background-image: linear-gradient(
    45deg,
    rgb(222, 57, 165) 10%,
    transparent 30%,
    rgb(222, 57, 165) 40%,
    transparent 50%,
    rgb(222, 57, 165) 60%,
    transparent 70%,
    transparent
  );
}
</style>
