<template>
  <div id="subject-count">
    <b-modal
      ref="go-settings"
      id="go-settings"
      centered
      title="Diko'ya Hoşgeldiniz"
      hide-footer
      no-close-on-backdrop
      ok-title="Hesabınızı Düzenleyin"
      size="lg"
      @hide="modalCloseSettings"
      @ok="goSettings"
    >
      <b-col>
        <b-card-text class="mb-1">
          <h3 class="text-center mb-1">
            {{
              $t(
                "Congratulations on joining Diko! We're thrilled to have you on board, and we're here to make your experience as enjoyable and informative as possible."
              )
            }}
            <span>&#128075;</span>
          </h3>
          <b-row class="match-height">
            <b-col md="12" class="m-1">
              <b-card class="">
                <h2 class="text-center m-1">{{ $t("How to Get Approved") }} &#9989;</h2>
                <p>
                  {{
                    $t(
                      "Getting started is easy! Follow these simple steps to get approved:"
                    )
                  }}
                </p>
                <ul>
                  <li class="m-1">{{ $t("Complete your profile information.") }}</li>
                  <li class="m-1">{{ $t("Upload any required documents.") }}</li>
                  <li class="m-1">{{ $t("Approve you email address.") }}</li>
                </ul>
                <p>
                  {{
                    $t(
                      "Once approved, you'll gain access to all the amazing features!"
                    )
                  }}
                </p>
                <div class="d-flex justify-content-center"><b-button
                  variant="primary"
                  class="d-flex align-items-center mt-1"
                  @click="goSettings"
                >
                  <feather-icon
                    icon="Edit3Icon"
                    size="18"
                    class="mr-1"
                  ></feather-icon
                  >Hemen Başla</b-button
                ></div>
                
              </b-card>
            </b-col>
            <!--<b-col md="6">
              <b-card>
                <h2>{{ $t("Discover Our App: Information and Videos") }}</h2>
                <ul>
                  <li class="mb-1">
                    {{
                      $t(
                        "Want to learn more about how to use Diko? Check out our video guide."
                      )
                    }}
                  </li>
                  <li class="mb-1">
                    {{ $t(
                      "Want to learn more about Diko? Check out our FAQ page."
                    ) }}
                  </li>
                </ul>
                
                <div class="d-flex justify-content-center">
                  <b-button
                  variant="success"
                  class="d-flex align-items-center"
                  @click="goGuide"
                >
                  <feather-icon
                    icon="YoutubeIcon"
                    size="18"
                    class="mr-1"
                  ></feather-icon
                  >Video Rehbere Git</b-button
                >
                </div>
                
              </b-card>
            </b-col>-->
          </b-row>
        </b-card-text>
        <!--<iframe width="100%" height="500" src='https://www.youtube.com/embed/videoseries?si=D_NOwQIGsoH3ft1H&amp;list=PLL77PSwRRYAUNUZGtf1GrYd85jI4Jxqz_' title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>-->
        <b-card-text class="d-flex justify-content-between mt-1 mb-1">
        </b-card-text>
      </b-col>
    </b-modal>
    <b-row class="d-flex justify-content-center">
      <!-- <Congratulation /> -->
    </b-row>
    <!-- Stats Card Vertical -->
    <b-row class="match-height">
      <b-col xl="3" md="3" sm="6">
        <b-link
          :to="{
            name: 'clientlist',
            params: { sort: 'program.active=true&active=true' },
          }"
        >
          <statistic-card-vertical
            icon="UserCheckIcon"
            :statistic="stats.activeSubjectCount || 0"
            color="success"
            id="subject-count-card"
            :statistic-title="$t('Active Subject Count')"
          />
        </b-link>
      </b-col>
      <b-col xl="3" md="3" sm="6">
        <b-link
          :to="{
            name: 'clientlist',
            params: { sort: 'program.active=false&active=true' },
          }"
        >
          <statistic-card-vertical
            color="warning"
            icon="UserIcon"
            :statistic="stats.onHoldSubjectCount || 0"
            :statistic-title="$t('On Hold Subject Count')"
          />
        </b-link>
      </b-col>
      <b-col xl="3" md="3" sm="6">
        <b-link
          :to="{
            name: 'clientlist',
            params: { sort: 'active=false' },
          }"
        >
          <statistic-card-vertical
            color="warning"
            icon="UserMinusIcon"
            :statistic="stats.passiveSubjectCount || 0"
            :statistic-title="$t('Passive Subjects')"
          />
        </b-link>
      </b-col>
      <b-col xl="3" md="3" sm="6">
        <b-link
          :to="{
            name: 'clientlist',
            params: {
              sort: `program.exercisesPrinted={'gt':'program.exercisesDone'}`,
            },
          }"
        >
          <statistic-card-vertical
            :color="stats.exerciseCount > 24 ? 'danger' : 'warning'"
            icon="FileTextIcon"
            :statistic="stats.exerciseCount || 0"
            :statistic-title="$t('Exercises Awaiting Result')"
          />
        </b-link>
      </b-col>
      <!-- <b-col xl="2" md="4" sm="6">
                <statistic-card-vertical
                  :color="this.stats.testCount > 0 ? 'success' : 'danger'"
                  icon="ZapIcon"
                  :statistic="this.stats.testCount"
                  statistic-title="Kalan Terapi Hakkı"
                />
              </b-col>

              <b-col xl="2" md="4" sm="6">
                <statistic-card-vertical
                  color="primary"
                  icon="HeartIcon"
                  statistic="Diko Test"
                  statistic-title=" ile 124 Saat"
                />
              </b-col>
              <b-col xl="2" md="4" sm="6">
                <statistic-card-vertical
                  hide-chart
                  color="danger"
                  icon="AlertOctagonIcon"
                  :statistic="this.stats.testCount"
                  statistic-title="Kalan Test Hakkı"
                />
              </b-col> -->
    </b-row>
    <!-- <b-row> -->
    <!-- <b-card class="w-100" style="height: 10px"> -->
    <!-- <span
                style="background-color: #34bde6"
                :style="{
                  width: `${
                    successData.change < 0
                      ? successData.score + successData.change
                      : successData.score
                  }%`,
                }"
              >
                {{ successData.score }}%
              </span>
              <span
                :style="{
                  'min-width': `${Math.abs(successData.change)}%`,
                  'background-color': `${
                    successData.change < 0 ? 'rgba(234, 84, 85,0.75)' : 'rgba(40, 199, 111,0.75)'
                  }`,
                }"
              >
                {{ successData.change }}%
              </span> -->
    <b-card>
      <h4 style="text-align: center">{{ $t("Overall Success") }}</h4>
      <SuccessBar
        :pausedState="true"
        :transition="true"
        :score="successData.score"
        :change="successData.change"
      />
    </b-card>
    <!-- </b-card> -->
    <!-- </b-row> -->
    <b-row class="d-flex justify-content-center match-height mt-2">
      <!-- <b-col xl="4">
                <CardAnalyticGoalOverview />
              </b-col> -->
      <b-col xl="6">
        <!-- <statistic-card-with-bar-chart
                  icon="TrendingUpIcon"
                  statistic="?"
                  statistic-title="Danışan Genel Durum"
                  :chart-data="seriesComputed"
                  :chart-options="optionsComputed"
                  color="success"
                /> -->
        <slot>
          <PastTimeline :refresh="analyticsRefresh" />
        </slot>
        <!-- <h4 class="mt-3" style="text-align: center">Danışanların Genel Durumu</h4> -->
        <!-- <vue-apex-charts
                  height="500"
                  width="100%"
                  :options="optionsComputed"
                  :series="seriesComputed"
                /> -->
      </b-col>
      <b-col xl="6">
        <slot>
          <ApproachingTimeline :refresh="analyticsRefresh" />
        </slot>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BLink,
  BCard,
  BRipple,
  BModal,
  BEmbed,
} from "bootstrap-vue";
import StatisticCardVertical from "@core/components/statistics-cards/StatisticCardVertical.vue";
import Congratulation from "./Congratulation.vue";
import CardAnalyticGoalOverview from "./CardAnalyticGoalOverview.vue";
import StatisticCardWithBarChart from "./StatisticCardWithBarChart.vue";
import ApproachingTimeline from "./ApproachingTimeline.vue";
import PastTimeline from "./PastTimeline.vue";
import axios from "@axios";

import SuccessBar from "./SuccessBar.vue";
// import chartOpts from "./barChartOptions.js";
import VueApexCharts from "vue-apexcharts";
import Ripple from "vue-ripple-directive";
// import userStatistic from "./userStatistic.js";
const {
  axiosRoutes: { auth: path },
} = require("/G_CONFIG");

export default {
  components: {
    BEmbed,
    BRow,
    BCol,
    BLink,
    BCard,
    StatisticCardVertical,
    Congratulation,
    CardAnalyticGoalOverview,
    StatisticCardWithBarChart,
    ApproachingTimeline,
    PastTimeline,
    BButton,
    VueApexCharts,
    SuccessBar,
    SuccessBar,
    BModal,
  },
  directives: {
    Ripple,
  },
  computed: {
    successData() {
      console.log(this.chartData);
      return this.chartData;
    },
    analyticsRefresh() {
      return this.analyticsrefresh + this.manualRefresh;
    },
  },
  watch: {
    // analyticsrefresh(newVal, oldVal) {
    // },
  },
  //   seriesComputed() {
  //     return this.chartData.series;
  //   },
  //   optionsComputed() {
  //     return { ...chartOpts, ...this.barChartOptions };
  //   },
  // },
  props: {
    analyticsrefresh: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      // barChartOptions: chartOpts,
      stats: {
        activeSubjectCount: 0,
        exerciseCount: 0,
        subjectCount: 0,
        testCount: 0,
      },
      manualRefresh: 0,
      chartData: { change: 0, total: 0 },

      //   series: [
      //     {
      //       name: "",
      //       data: [],
      //     },
      //     {
      //       name: "ARTIŞ",
      //       data: [],
      //       color: $themeColors.success,
      //     },
      //     {
      //       name: "DÜŞÜŞ",
      //       data: [],
      //       color: $themeColors.danger,
      //     },
      //   ],
      // },
    };
  },
  mounted() {
    axios.get(path.dashboardStats).then((fetched) => {
      // console.log(fetched.data);
      // this.barChartOptions.xaxis = {
      //   ...this.barChartOptions.xaxis,
      //   categories: fetched.data.chartData.names,
      //   overwriteCategories: fetched.data.chartData.names,
      // };
      this.stats.activeSubjectCount = fetched.data.activeSubjectCount;
      this.stats.exerciseCount = fetched.data.exerciseCount;
      this.stats.activeSubjectCount = fetched.data.activeSubjectCount;
      this.stats.onHoldSubjectCount = fetched.data.onHoldSubjectCount;
      this.stats.passiveSubjectCount = fetched.data.passiveSubjectCount;
      // this.chartData.series = [
      //   { name: "", data: fetched.data.chartData.scores },
      //   {
      //     name: "ARTIŞ",
      //     data: fetched.data.chartData.increase,
      //     color: $themeColors.success,
      //   },
      //   {
      //     name: "DÜŞÜŞ",
      //     data: fetched.data.chartData.decrease,
      //     color: $themeColors.danger,
      //   },
      // ]; /*fetched.data.series;*/
      this.chartData = fetched.data.chartData;
    });
    if (!this.$can("create", "S.Exercise")) {
      this.$bvModal.show("go-settings");
    }
    /* console.log(this.stats.activeSubjectCount, "activeSubjectCount") */
    this.interval = setInterval(() => {
      this.rerenderAnalytics();
    }, 60000);
  },
  destroyed() {
    clearInterval(this.interval);
  },
  methods: {
    rerenderAnalytics() {
      console.log("rerenderAnalytics");
      this.manualRefresh++;
    },
    goSettings() {
      this.$router.push("/account-settings");
    },
    goGuide() {
      this.$router.push("/pages/guide");
    },
    modalCloseSettings() {
      this.$router.push("/account-settings");
    },
  },
};
</script>
<style lang="scss"></style>
