<template>
  <b-card
    text-variant="center"
    class="card card-congratulations"
  >
    <!-- images -->
    <!--  <b-img :src="require('@/assets/images/elements/decore-left.png')" class="congratulations-img-left" />
    <b-img :src="require('@/assets/images/elements/decore-right.png')" class="congratulations-img-right" /> -->
    <!--/ images -->

    <b-avatar
      variant="danger"
      size="70"
      class="shadow mb-2"
    >
      <feather-icon
        size="28"
        icon="AlertCircleIcon"
      />
    </b-avatar>
    <h1 class="mb-1 mt-50 text-white">
      Merhaba John,
    </h1>
    <b-card-text class="m-auto w-75">
      {{$t('Today you have')}} <strong>5</strong> {{$t('sessions.')}} {{$t('You can check your calendar and prepare your exercises.')}}
    </b-card-text>
  </b-card>
</template>

<script>
import {
  BCard, BImg, BAvatar, BCardText,
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'

export default {
  components: {
    BCard,
    BAvatar,
    BImg,
    BCardText,
    BCardActions,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
