var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"subject-count"}},[_c('b-modal',{ref:"go-settings",attrs:{"id":"go-settings","centered":"","title":"Diko'ya Hoşgeldiniz","hide-footer":"","no-close-on-backdrop":"","ok-title":"Hesabınızı Düzenleyin","size":"lg"},on:{"hide":_vm.modalCloseSettings,"ok":_vm.goSettings}},[_c('b-col',[_c('b-card-text',{staticClass:"mb-1"},[_c('h3',{staticClass:"text-center mb-1"},[_vm._v(" "+_vm._s(_vm.$t( "Congratulations on joining Diko! We're thrilled to have you on board, and we're here to make your experience as enjoyable and informative as possible." ))+" "),_c('span',[_vm._v("👋")])]),_c('b-row',{staticClass:"match-height"},[_c('b-col',{staticClass:"m-1",attrs:{"md":"12"}},[_c('b-card',{},[_c('h2',{staticClass:"text-center m-1"},[_vm._v(_vm._s(_vm.$t("How to Get Approved"))+" ✅")]),_c('p',[_vm._v(" "+_vm._s(_vm.$t( "Getting started is easy! Follow these simple steps to get approved:" ))+" ")]),_c('ul',[_c('li',{staticClass:"m-1"},[_vm._v(_vm._s(_vm.$t("Complete your profile information.")))]),_c('li',{staticClass:"m-1"},[_vm._v(_vm._s(_vm.$t("Upload any required documents.")))]),_c('li',{staticClass:"m-1"},[_vm._v(_vm._s(_vm.$t("Approve you email address.")))])]),_c('p',[_vm._v(" "+_vm._s(_vm.$t( "Once approved, you'll gain access to all the amazing features!" ))+" ")]),_c('div',{staticClass:"d-flex justify-content-center"},[_c('b-button',{staticClass:"d-flex align-items-center mt-1",attrs:{"variant":"primary"},on:{"click":_vm.goSettings}},[_c('feather-icon',{staticClass:"mr-1",attrs:{"icon":"Edit3Icon","size":"18"}}),_vm._v("Hemen Başla")],1)],1)])],1)],1)],1),_c('b-card-text',{staticClass:"d-flex justify-content-between mt-1 mb-1"})],1)],1),_c('b-row',{staticClass:"d-flex justify-content-center"}),_c('b-row',{staticClass:"match-height"},[_c('b-col',{attrs:{"xl":"3","md":"3","sm":"6"}},[_c('b-link',{attrs:{"to":{
          name: 'clientlist',
          params: { sort: 'program.active=true&active=true' },
        }}},[_c('statistic-card-vertical',{attrs:{"icon":"UserCheckIcon","statistic":_vm.stats.activeSubjectCount || 0,"color":"success","id":"subject-count-card","statistic-title":_vm.$t('Active Subject Count')}})],1)],1),_c('b-col',{attrs:{"xl":"3","md":"3","sm":"6"}},[_c('b-link',{attrs:{"to":{
          name: 'clientlist',
          params: { sort: 'program.active=false&active=true' },
        }}},[_c('statistic-card-vertical',{attrs:{"color":"warning","icon":"UserIcon","statistic":_vm.stats.onHoldSubjectCount || 0,"statistic-title":_vm.$t('On Hold Subject Count')}})],1)],1),_c('b-col',{attrs:{"xl":"3","md":"3","sm":"6"}},[_c('b-link',{attrs:{"to":{
          name: 'clientlist',
          params: { sort: 'active=false' },
        }}},[_c('statistic-card-vertical',{attrs:{"color":"warning","icon":"UserMinusIcon","statistic":_vm.stats.passiveSubjectCount || 0,"statistic-title":_vm.$t('Passive Subjects')}})],1)],1),_c('b-col',{attrs:{"xl":"3","md":"3","sm":"6"}},[_c('b-link',{attrs:{"to":{
          name: 'clientlist',
          params: {
            sort: "program.exercisesPrinted={'gt':'program.exercisesDone'}",
          },
        }}},[_c('statistic-card-vertical',{attrs:{"color":_vm.stats.exerciseCount > 24 ? 'danger' : 'warning',"icon":"FileTextIcon","statistic":_vm.stats.exerciseCount || 0,"statistic-title":_vm.$t('Exercises Awaiting Result')}})],1)],1)],1),_c('b-card',[_c('h4',{staticStyle:{"text-align":"center"}},[_vm._v(_vm._s(_vm.$t("Overall Success")))]),_c('SuccessBar',{attrs:{"pausedState":true,"transition":true,"score":_vm.successData.score,"change":_vm.successData.change}})],1),_c('b-row',{staticClass:"d-flex justify-content-center match-height mt-2"},[_c('b-col',{attrs:{"xl":"6"}},[_vm._t("default",function(){return [_c('PastTimeline',{attrs:{"refresh":_vm.analyticsRefresh}})]})],2),_c('b-col',{attrs:{"xl":"6"}},[_vm._t("default",function(){return [_c('ApproachingTimeline',{attrs:{"refresh":_vm.analyticsRefresh}})]})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }