<template>
  <div>
    <DashboardComponents :analyticsrefresh="timelineRefresh" />
    <div class="mt-5">
      <Calendar @calendarchange="analyticRefresh" />
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BLink,
  BFormRadio,
  BFormRadioGroup,
  BFormGroup,
  BCardText,
} from "bootstrap-vue";

import Calendar from "@/views/apps/calendar/Calendar.vue";
import DashboardComponents from "@/views/dashboard/DashboardComponents.vue";

export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BFormGroup,
    BFormRadio,
    BFormRadioGroup,

    Calendar,
    DashboardComponents,
  },
  data() {
    return {
      locale: this.$i18n.locale,
      timelineRefresh: 0,
    };
  },
  watch: {
    locale(value) {
      this.$i18n.locale = value;
    },
  },
  methods: {
    analyticRefresh() {
      console.log("refreshAnalytics");
      this.timelineRefresh++;
    },
  },
};
</script>

<style>
body {
  transition: all 0.5s ease;
}
</style>
